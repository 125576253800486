import React from "react"
import Layout from "../components/layout"

export default function NotFound() {
    return (
        <Layout slug="/404/" title="Not Found">
            <div class="c-page-title">
                <h1>404 Not Found</h1>
            </div>

            <div class="c-404-message">
                <p>お探しのページは<br class="is-br-sp-show" />見つかりませんでした。</p>
            </div>
        </Layout>
    )
}